import {React,useState,useEffect} from 'react'
import Style from "./bookingSlot.module.css"
import BlackArrow from "../../assets/svg/leftArrowBlack.svg"
import BookingSlotWrapper from '../../components/bookingSlotWrapper/BookingSlotWrapper';
import Battery from "../../assets/svg/battery.svg";
import OilChange from "../../assets/svg/oil-change.svg";
import CarWash from "../../assets/svg/car-wash.svg";
import {useHistory, Link } from 'react-router-dom';
import { availabelSlots } from '../../api/service';
import DateFnsUtils from '@date-io/date-fns';
import Spinner from '../../components/spinner/index'
import {MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import SlotOrange from '../../assets/svg/slotO.svg';
import SlotGreen from '../../assets/svg/slotG.svg';
import SlotBlack from '../../assets/svg/slotB.svg';
import SlotRed from '../../assets/svg/slotR.svg';
import SlotA from '../../assets/svg/slotA.svg';



function BookingSlot(props) {
   const [selectedDate, setSelectedDate] = useState(new Date());
   const [slots, setSlots] = useState([]);
   const [loading, setLoading] = useState(true);
   let colorCount = 0;
   const dateString = new Date();
   
   const formatDate = (dateString) => {
      const options = { year: "numeric", month: "long", day: "numeric" }
      return new Date(dateString).toLocaleDateString(undefined, options)
    }
   const getColor = (count) => {
      switch(count) {
         case 0:
            return SlotOrange;
         case 1:
            return SlotGreen;
         case 2:
            return SlotBlack;
         case 3:
            return SlotRed;
         case 4:
            return SlotA;
            default:
               return SlotOrange;
      }
   };

   const getText = (count) => {
      switch(count) {
         case 0:
            return Style.orange;
         case 1:
            return Style.green;
         case 2:
            return Style.black;
         case 3:
            return Style.red;
         case 4:
            return Style.aqua;
            default:
               return Style.orange;
      }
   };
   


   const renderImage = (title) =>{
      if (title==='Oil')
      {
        return <div className="detailCard" ><img src={OilChange} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Car Wash')
      {
        return <div className="detailCard" ><img src={CarWash} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Battery')
      {
        return <div className="detailCard" ><img src={Battery} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
      
  }
   const history = useHistory();
   const apiObject = history.location.state.apiObject;
   const data={
      organization_id:apiObject.organization._id, 
      service_id:apiObject.object._id,
      date:moment(dateString).format("YYYY-MM-DD")
   }
   const handleDateChange = (date) => {
      setLoading(true);

      setSelectedDate(date);
     
      const data={
         organization_id:apiObject.organization._id, 
         service_id:apiObject.object._id,
         date:moment(date).format("YYYY-MM-DD")
      }
      availabelSlots(data).then(responce => {
         setSlots(responce.Data);
         setLoading(false);

         // console.log(responce.Data);
       });
   };

   useEffect(() => {
      availabelSlots(data).then(responce => {
         setSlots(responce.Data);
         setLoading(false);

         // console.log(responce.Data);
       });
        
    },)
    const handleRoute = (object,object2) =>{ 
       console.log(object2);
       let data={
          slotsData:object,
          organizationData:object2,
          selectedDate:selectedDate,
       }
       console.log(object);
      //  console.log(object.organizationServices);
      //  console.log(object.organizationServices);
      history.push({
         pathname: '/confirmBooking',
         state: { data: data }
       })
    }
    let services=renderImage(apiObject.object.service.title)
   // const [services, setServices] = useState(renderImage(apiObject.object.service.title));

   return (
      <div  className={Style.fullContainer}>
         <div className={Style.slotsTopWrapper}>
            <div className={`d-flex justify-content-between align-items-center calendarIcon`}>
            <Link to="/services">
               <img src={BlackArrow} alt="arrow-white" />
            </Link>
               <div className={Style.slotsTopText}>Available Slots</div>
               
            </div>
         </div>
         <div className={Style.slotsDate}>
            <div className={Style.slotsDateText}>
              <div className={Style.carWrapper}>
              { services}
              <div className={Style.cardWrapperText}>{apiObject.object.option.title}</div>
              </div>
              <div className={Style.border}></div>

               <div className={Style.dateWrapper}>
                  <span className={Style.today}>Today</span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                     value={selectedDate}
                     minDate={moment().toDate()}
                     onChange={handleDateChange}
                  />
               </MuiPickersUtilsProvider>
                  <span className={Style.date}>{formatDate(dateString)}</span>
               </div>
            </div>
         </div>


         { loading ? <Spinner /> : null }

         {slots.length<=0 && !loading ?<div className={Style.recordWrapper}>No result found</div>:null}

         {slots.map(function(object, i){
            if(colorCount  === 5) {
               colorCount = 0;
            }
            const color = getColor(colorCount);
            const text = getText(colorCount);
            colorCount += 1;
            return (
               <>
                  <div onClick={()=>{handleRoute(object,apiObject,selectedDate)}} className={Style.serviceLink}>
                     <BookingSlotWrapper obj={object} key={i} count={i} color={color} text={text} />
                  </div>
               </>
            )
         })}
         
         
      </div>
   )
}

export default BookingSlot
