import {React, useState} from 'react';
import "./serviceDetail.css";
import "../../components/CardRating"
import { useHistory } from "react-router-dom";
import ArrowWhite from "../../assets/svg/leftArrow.svg";
import AddressIcon from '../../assets/svg/addressIcon.svg';
import WebsiteIcon from '../../assets/svg/websiteIcon.svg';
import PhoneIcon from '../../assets/svg/phoneIcon.svg';
import Battery from "../../assets/svg/battery.svg";
import OilChange from "../../assets/svg/oil-change.svg";
import CarWash from "../../assets/svg/car-wash.svg";
import Style from "../../components/serviceCards/serviceCards.module.css";
import {Modal} from 'react-bootstrap'
import Select from "react-select"
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Refresh } from '@material-ui/icons';
import {filterArray} from '../../global/globalFunction'
import { IMAGE_BASE_URL } from '../../api/service';
import ReactImageFallback from "react-image-fallback";
import Default from "../../assets/organization/ic_organization.png";
import {Link} from 'react-router-dom';
import {useSelector } from "react-redux";


function ServiceDetail() {
   const history = useHistory();
   const data = useSelector((state)=>state.users);
   const apiObject = history.location.state ? history.location.state.apiObject : null;
   const filteredServices = history.location.state ? history.location.state.filteredServices : null;
   const [services, setServices] = useState(filteredServices);
   const [show, setShow] = useState(false);
   const [enableButon, setButton] = useState(true);
   const [list, setList] = useState([]);
   const [selectValue, setselectValue] = useState({selectedOption: "",service:""});

   if(history.location.state===undefined){
      history.push({pathname: '/services'});
      return <></>;
  }

   const onlyUnique=(arr, key)=>{
      return [...new Map(arr.map(item =>
          [item[key]['title'], item])).values()];
         
   }

   const handleService = (value) =>{

      let friends = apiObject.organizationServices.filter( function (object) {
         return object.service.title.toString() === value && object.type.type.toString() === data.vehicles[0].type.type
       });

       

       let filerArray=onlyUnique(friends,'option');       
       setList(filerArray);
       handleShow();
    }

   
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

   
  const renderImage = (title) =>{
      if (title==='Oil')
      {
        return <div className="detailCard" onClick={()=>{handleService(title)}}><img src={OilChange} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Car Wash')
      {
        return <div className="detailCard" onClick={()=>{handleService(title)}}><img src={CarWash} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Battery')
      {
        return <div className="detailCard" onClick={()=>{handleService(title)}}><img src={Battery} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
      
  }

  function handleAddrTypeChange(e) {
      const updateUsers =({...selectValue, selectedOption: e.option.title,service:e});
      setselectValue(updateUsers);
      setServices([e]);
      handleClose();
      setButton(false);
      
}

const shoot = () => {
   setServices(filteredServices);
   setselectValue([]);
   setButton(true);


 }

 const handleRoute = (object) =>{
   var key = "organizationServices";
   let filteredArray=filterArray(object.organizationServices,selectValue.selectedOption);
   delete object[key];
  let data={
     object:filteredArray[0],
     organization:object,

  }
   history.push({
      pathname: '/slots',
      state: { apiObject: data }
    })
 }

 const logoImage=(logo)=>{
   let baseUrl= IMAGE_BASE_URL+'organization/'+logo;
   return baseUrl;
}
  
   
   return (
      <>
         <div className="fullContainer">
            <div className="profileWrapper">
               <div className="coverImg">
               <ReactImageFallback
                    src={logoImage(apiObject.logo)}
                    fallbackImage={Default}
                    className="coverPhoto" />
               </div>
               <div className="profileImg">
                  <ReactImageFallback
                    src={logoImage(apiObject.logo)}
                    fallbackImage={Default}
                    className="profilePhoto" />
                  <div className="detailDesc">
                     <div className="detailHeading">{apiObject.business_title}</div>
                     <div className="detailaddress">{apiObject.phone}</div>
                     <div className="detailaddress">{apiObject.address}</div> 
                  </div>
               </div>
               <Link to="/services">
                  <div className="backBtn">
                     <img src={ArrowWhite} alt="backBtn" className="detailBackBtn" />
                  </div>
               </Link>
            </div>
            <div className="serviceText">
               Select Service
            </div>
            <div className="parentCard">
            {services.map(task=>{

            return  renderImage(task.service.title)

            })}
               <div>{selectValue.selectedOption}</div>
         { selectValue.selectedOption ? <div onClick={() => shoot()} className="showType"><Refresh /> Change</div> : null }

            </div>

               <Modal show={show} onHide={handleClose} size="sm" centered>
               <Modal.Header closeButton>
               </Modal.Header>
               <Modal.Body>

               <Select
                           onChange={handleAddrTypeChange}
                           options={list}
                           getOptionLabel={(list)=>list.option.title} 
                           getOptionValue={(list)=>list.option._id}
                           isSearchable={true}
                           placeholder="Select your vehicle"
                           menuColor='black'
                        />
               </Modal.Body>
               </Modal>

            <div className="serviceBottom">
               <div className="serviceBottomDetail">
                  {apiObject.business_description}
               </div>
               {apiObject.website ?
                  <div className="serviceBottomDetail">
                  <img src={WebsiteIcon} alt="addressImg" className="serviceIcon" />
                  <div>{apiObject.website}</div>
               </div> : ''
               }
               {apiObject.email ? 
                  <div className="serviceBottomDetail">
                  <EmailOutlinedIcon className="serviceIcon" />
                  <div>{apiObject.email}</div> 
               </div> : ''
               }
               {apiObject.facebook ?
                  <div className="serviceBottomDetail">
                  <FacebookOutlinedIcon className="serviceIcon" />
                  <div>{apiObject.facebook}</div>
               </div> : ''
               }
               {apiObject.twitter ? 
                  <div className="serviceBottomDetail">
                  <TwitterIcon className="serviceIcon" />
                  <div>{apiObject.twitter}</div>
               </div> : ''
               }
               {apiObject.instagram ? 
                  <div className="serviceBottomDetail">
                  <InstagramIcon className="serviceIcon" />
                  <div>{apiObject.instagram}</div>
               </div> : ''
               }
               {apiObject.phone ?
                  <div className="serviceBottomDetail">
                  <img src={PhoneIcon} alt="addressImg" className="serviceIcon" />
                  <div>{apiObject.phone}</div>
               </div> : ''
               }
               {apiObject.address ? 
                  <div className="serviceBottomDetail">
                  <img src={AddressIcon} alt="addressImg" className="serviceIcon" />
                  <div>{apiObject.address}</div>
               </div> : ''
               }
            </div>
            <div className="text-center pb-4" onClick={()=>{handleRoute(apiObject)}}>
              <button disabled={enableButon} className={enableButon ? "btnGrey" : "btnBlack"}>Find Booking</button>
            </div>
            
         </div>
      </>
   )
}

export default ServiceDetail;
