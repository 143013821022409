import {React,useState} from 'react'
import Style from "./confirmBooking.module.css";
import "../../components/CardRating"
import defaultCarousel from "../../assets/organization/default.png";
// import defaultLoader from "../../assets/images/loader.jpg";
import CarWash from "../../assets/svg/car-wash.svg"
import AddressIcon from '../../assets/svg/addressIcon.svg';
import WebsiteIcon from '../../assets/svg/websiteIcon.svg';
import PhoneIcon from '../../assets/svg/phoneIcon.svg';
import {useHistory } from 'react-router-dom';
import OilChange from "../../assets/svg/oil-change.svg";
import Battery from "../../assets/svg/battery.svg";
import {formatTime,formatDate,showSuccess,showError} from '../../global/globalFunction';
import { createBooking } from '../../api/service';
import { toast } from "react-toastify";
import {useSelector } from "react-redux"
import Loader from "react-loader-spinner";
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IMAGE_BASE_URL } from '../../api/service';
import ReactImageFallback from "react-image-fallback";
import Default from "../../assets/organization/ic_organization.png";
import { FaHeart } from 'react-icons/fa';



import "react-toastify/dist/ReactToastify.css";
toast.configure();
function ConfirmBooking() {
   const [loader, setButtonLoader] = useState(false);

   const loginUser = useSelector((state)=>{
      return state.users
      })
//   const [setBookingVlaue, setBookingData] = useState(renderImage(useData.service.title));

   const history = useHistory();
   const data = history.location.state.data;
   const organization=data.organizationData.organization
   console.log(organization);
   let filtered = loginUser.favouriteOrganizations.filter(t=>t ===organization._id);
   
   const useData=data.organizationData.object
   const renderImage = (title) =>{
      if (title==='Oil')
      {
        return <div className="detailCard" ><img src={OilChange} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Car Wash')
      {
        return <div className="detailCard" ><img src={CarWash} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
  
      if (title==='Battery')
      {
        return <div className="detailCard" ><img src={Battery} className={Style.bottomWrapperImg} alt="" /> <span className={Style.itemName}>{title}</span></div>
      }
      
  }

  const booking=()=>{
      setButtonLoader(true)
     let object={
      user_id: loginUser._id,
      queue_id: data.slotsData.queue_id,
      vehicle_id: loginUser.vehicles[0]._id,
      organization_id: data.organizationData.organization._id,
      serviceOffering_id: data.organizationData.object._id,
      auth_user_id: loginUser._id,
      date: formatDate(data.selectedDate),
      time_from: data.slotsData.timeRange.start,
      time_to: data.slotsData.timeRange.end
    }

    
   createBooking(object).then(responce => {

      if(responce.Status===200){
         showSuccess(responce.Message);

         history.push({
            pathname: '/services',
          })
      }else{
         showError(responce.Message);
      }

    });
  
  }
  const logoImage=(logo)=>{
   let baseUrl= IMAGE_BASE_URL+'organization/'+logo;
   return baseUrl;
}
let services=renderImage(useData.service.title)

   return (
      <>
         <div className="fullContainer">
            <div className={Style.profileWrapper}>
               {organization.banners.length>0?<Carousel controls={false} interval={1000} >
                     {organization.banners.map(object => (
                        <Carousel.Item >
                           <ReactImageFallback
                           src={IMAGE_BASE_URL+'organization/banners/'+object.banner_image}
                           fallbackImage={defaultCarousel}
                           className={Style.coverPhoto} />
                          
                        </Carousel.Item>
                     ))}
               </Carousel> :
               <Carousel controls={false} interval={1000} >
                        <Carousel.Item >
                           <ReactImageFallback
                           fallbackImage={defaultCarousel}
                           className={Style.coverPhoto} />
                        </Carousel.Item>
               </Carousel>
               }

               <div className={Style.profileImg}>
               <ReactImageFallback
                    src={logoImage(organization.logo)}
                    fallbackImage={Default}
                    className={Style.profilePhoto} />
                  <div className={Style.detailDesc}>
                     <div className={Style.detailHeading}>{organization.business_title}</div>
                     <div className="detailaddress">{organization.phone}</div>
                     <div className="detailaddress">{organization.address}</div>
                  </div>
               </div>
               {filtered.length>0?<FaHeart className={Style.fontclass}  />:''}
               <div className={Style.serviceText}>
               Selected Service
            </div>
            <div className={Style.confirmBookingCardWrapper}>
               <div className={Style.confirmBookingCard}>
                  { services}
               </div>
               <div className={Style.confirmBookingCardRightText}>{useData.option.title}</div>
            </div>
            </div>
            
            
            <div className={Style.timingWrapper}>
               <div className={Style.date}>
                  <span className={Style.timingWrapperLabel}>Date: </span><span className={Style.timingWrapperText}>{formatDate(data.selectedDate)}</span>
               </div>
               <div className={Style.border}></div>
               <div className={Style.time}>
                  <span className={Style.timingWrapperLabel}>Time: </span><span className={Style.timingWrapperText}>{formatTime(data.slotsData.timeRange.start)}</span>
               </div>
            </div>
            <div className={Style.serviceBottom}>
               <div className={Style.numberPlate}>
                  <div className={Style.numberPlateLeft}>{loginUser.vehicles[0].state}</div>
                  {/* <img src={Dubai} alt="dubai" /> */}
                  <div className={Style.numberPlateLeft}>{loginUser.vehicles[0].registration_number}</div>
               </div>
               <div className={Style.serviceBottomDetail}>
                  {organization.business_description}
               </div>
               {organization.website ?
                  <div className={Style.serviceBottomDetail}>
                  <img src={WebsiteIcon} alt="addressImg" className="serviceIcon" />
                  <div>{organization.website}</div>
               </div> : ''
               }
               {organization.email ? 
                  <div className={Style.serviceBottomDetail}>
                  <EmailOutlinedIcon className="serviceIcon" />
                  <div>{organization.email}</div> 
               </div> : ''
               }
               {organization.facebook ?
                  <div className={Style.serviceBottomDetail}>
                  <FacebookOutlinedIcon className="serviceIcon" />
                  <div>{organization.facebook}</div>
               </div> : ''
               }
               {organization.twitter ? 
                  <div className={Style.serviceBottomDetail}>
                  <TwitterIcon className="serviceIcon" />
                  <div>{organization.twitter}</div>
               </div> : ''
               }
               {organization.instagram ? 
                  <div className={Style.serviceBottomDetail}>
                  <InstagramIcon className="serviceIcon" />
                  <div>{organization.instagram}</div>
               </div> : ''
               }
               {organization.phone ?
                  <div className={Style.serviceBottomDetail}>
                  <img src={PhoneIcon} alt="addressImg" className="serviceIcon" />
                  <div>{organization.phone}</div>
               </div> : ''
               }
               {organization.address ? 
                  <div className={Style.serviceBottomDetail}>
                  <img src={AddressIcon} alt="addressImg" className="serviceIcon" />
                  <div>{organization.address}</div>
               </div> : ''
               }
            </div>
               <div className={`text-center pb-4`}>
               <button className="btnBlack" onClick={()=>{booking()}}>Confirm Booking
           {
               loader ? 
             <span>
               <Loader
             type="Circles"
             color="#00BFFF"
             height={27}
             width={27}
           /></span>:null
             }</button>
               </div>
            
         </div>
      </>
   )
}

export default ConfirmBooking;
